// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import ArticlesView from '@/views/ArticlesView.vue';
import ServicesView from '@/views/ServicesView.vue';
import HomeView from '@/views/HomeView.vue';
import LawyersView from '@/views/LawersView.vue';
import ContactView from '@/views/ContactView.vue';
import AdminView from '@/views/AdminView.vue';
import CalculatorsPage from '@/views/Calculators.vue';

const routes = [
  { path: '/', name: 'HomeView', component: HomeView },
  { path: '/services', name: 'sevices', component: ServicesView, props: true },
  { path: '/article/:id', name: 'article', component: ArticlesView, props: true },
  { path: '/lawyers', name: 'LawyersView', component: LawyersView, props: true },
  { path: '/contacts', name: 'contacts', component: ContactView, props: true },
  { path: '/admin', name: 'admin', component: AdminView, props: true },
  { path: '/calculators', name: 'calculators', component: CalculatorsPage, props: true },


];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
