<template>
  <div>
    <HeaderComponent />
    <section class="content">
      <div class="sidebar">
        <div class="category">
          <div class="title">
            <h2>Физическим лицам</h2>
          </div>
          <ul>
            <li v-for="service in individualServices" :key="service.id" @click="loadArticle(service.articleId)">
              {{ service.name }}
            </li>
          </ul>
        </div>
        <div class="category">
          <div class="title">
            <h2>Юридическим лицам</h2>
          </div>
          <ul>
            <li v-for="service in businessServices" :key="service.id" @click="loadArticle(service.articleId)">
              {{ service.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="articles">
        <div v-if="article" class="article">
          <!-- Dynamic heading based on article type -->
          <div class="article-header">
            <h1 v-if="article.category === 'individuals'">Физическим лицам</h1>
            <h1 v-else-if="article.category === 'businesses'">Юридическим лицам</h1>
          </div>
          <div class="article-block">
            <h1>{{ article.title }}</h1>
            <div class="text-block" v-html="article.content"></div>
          </div>
          <div class="article-block" v-if="article.title2 && article.content2">
            <h1>{{ article.title2 }}</h1>
            <div v-html="article.content2"></div>
          </div>
        </div>
        <div v-else>
          <p>Статья не найдена.</p>
        </div>
      </div>
    </section>
    <FooterComponent />
  </div>
</template>

<script>
import { db } from '@/store/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from '@/components/HeaderComponent.vue';

export default {
  name: 'ArticlesView',
  components: {
    HeaderComponent,
    FooterComponent
  },
  data() {
    return {
      article: null,
      individualServices: [],
      businessServices: [],
    };
  },
  async mounted() {
    await this.fetchServices();
    const articleId = this.$route.params.id;
    if (articleId) {
      await this.loadArticle(articleId);
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.loadArticle(newId);
        }
      }
    }
  },
  methods: {
    async fetchServices() {
      try {
        const servicesRef = collection(db, 'services');
        const querySnapshot = await getDocs(servicesRef);
        querySnapshot.forEach((doc) => {
          const service = doc.data();
          if (service.type === 'individuals') {
            this.individualServices.push({ id: doc.id, ...service });
          } else if (service.type === 'businesses') {
            this.businessServices.push({ id: doc.id, ...service });
          }
        });
      } catch (error) {
        console.error('Ошибка при загрузке услуг:', error);
      }
    },
    async loadArticle(articleId) {
      console.log('Article ID:', articleId); // Log the article ID for debugging
      try {
        const articlesRef = collection(db, 'articles');
        const q = query(articlesRef, where('id', '==', articleId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            this.article = doc.data();
            console.log('Article data:', this.article); // Log the article data for debugging
          });
        } else {
          console.log('Нет такой статьи!');
          this.article = null;
        }
      } catch (error) {
        console.error('Ошибка при загрузке статьи:', error);
        this.article = null;
      }
    }
  }
};
</script>

<style scoped>
.content {
  display: flex;
  padding: 0 100px;
  padding-top: 200px;
  background: #f9f9f9;
}

h1 {
  color: #31473A;
}
.title{
  background: #D6DAD8;
  align-items: center;
  color: #31473A;
  align-content: center;
  padding: 10px 0;
}
.sidebar {
  display: grid;
  flex-direction: column;
  min-width: 220px;
  background-color: #ffffff00;
  margin-right: 0px;
  margin-top: 20px;
  gap: 20px;
}

.category {
  margin-bottom: 20px;
  background-color: #ffffff;
}

.category h2 {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.category ul {
  list-style-type: none;
  padding: 0;

}

.category li {
  cursor: pointer; /* Indicate clickable items */
  text-decoration: none;
  color: #000;
  margin: 10px 20px;
  max-width: 200px; /* Set a maximum width for the article block */
  word-wrap: break-word; /* Ensure that long words are wrapped */
}

.category li:hover {
  text-decoration: underline; /* Underline on hover */
}

.articles {
  flex: 1;
  padding: 20px;
}

.article-header {
  margin-bottom: 20px;
}

.article-block {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 30px 50px;
  max-width: 860px; /* Set a maximum width for the article block */
  word-wrap: break-word; /* Ensure that long words are wrapped */
}

.article-block h1, .article-block h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.article-block div {
  font-size: 16px;
}

.text-block {
  max-width: 860px; /* Match the max-width to ensure consistent wrapping */
  word-wrap: break-word; /* Ensure that long words are wrapped */
}

@media screen and (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;
    padding-top: 100px;
    background: #f9f9f9;
  }

  .article-block {
    margin-bottom: 20px;
    background-color: #fff;
    padding: 20px 20px;
    max-width: 860px; /* Set a maximum width for the article block */
    word-wrap: break-word; /* Ensure that long words are wrapped */
  }

  .articles {
    flex: 1;
    padding: 0px;
  }

  .sidebar {
    display: grid;
    flex-direction: column;
    width: 100%;  background-color: #ffffff00;
    margin-right: 0px;
    margin-top: 20px;
    gap: 20px;
  }

  .category {
    margin-bottom: 20px;
    background-color: #ffffff;
  }
}
</style>
