// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";  // Импортируем метод getFirestore
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import getStorage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDeJIPQJMTw8b4iZeNhpZWKd1NFQTwCrRI",
  authDomain: "lawyers-92e22.firebaseapp.com",
  projectId: "lawyers-92e22",
  storageBucket: "lawyers-92e22.appspot.com",
  messagingSenderId: "212428574881",
  appId: "1:212428574881:web:c47a787a1c8a0284cc60e0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and export it
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize storage

export { db, auth, storage};
